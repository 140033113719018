import { Link } from 'gatsby'
import React from 'react'
import './testimonials.scss'

export default function Testimonials() {
    return (
        <div className="testimonials-wrapper">
        <h3>Don't just take our word for it...</h3>
        <div className="testimonials">
            <h4>Rockwood School District</h4>
            <p>"...a note to tell you how pleased we are with the Tomcat "Edge" Oscillating Stick 
                Scrubber-Stripper and "Minimag" Automatic scrubbers."</p>
            <Link to="/">View Full Letter</Link>
            </div>
        <div className="testimonials">
            <h4>Hilton Hotels</h4>
            <p>"I have had the opportunity to purchase many different 
                brands of scrubbers through the years for different 
                types of facilities and I can tell you that my company 
                will be sticking with Tomcat for our scrubber needs."</p>
            <Link to="/">View Full Letter</Link>
        </div>
        <div className="testimonials">
            <h4>Golden Age Farms</h4>
            <p>"...the machines continue to impress us with their ability 
                to clean and an almost complete lack of required maintenance."</p>
            <Link to="/">View Full Letter</Link>
            </div>
        <div className="testimonials">
            <h4>Hilton Hotels</h4>
            <p>"I have had the opportunity to purchase many different 
                brands of scrubbers through the years for different 
                types of facilities and I can tell you that my company 
                will be sticking with Tomcat for our scrubber needs."</p>
            <Link to="/">View Full Letter</Link>
        </div>
        {/* Create dynamic template for tesimonials */}
        </div>
    )
}
