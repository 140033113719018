import React from 'react';
import { Link } from 'gatsby';
import './indexgallery.scss';
import '../../../styles.scss';
import HangerImage from "../../indexPage/images/carbon-hanger.jpg";
import RetailImage from "../../indexPage/images/crz-retail.jpg";
import WarehouseImage from "../../indexPage/images/ex-warehouse-yellow-lines-04-12-21.jpg";
import ManufacturingImage from "../../indexPage/images/gt-manufacturing.jpg";
import SportsImage from "../../indexPage/images/sport-healthcare-hallway-03-15-21.jpg";

export default function IndexGallery() {
    return (
        <div className="page-wrapper">
            <div className="index-gallery">
            <div>
            <span>
                <h4>CONTACT A DEALER</h4>
                </span>
                <img src={HangerImage} alt="" /></div>
            <div>
            <span>THE TOMCAT DIFFERENCE</span>
            <Link to="/tomcat-difference"><img src={RetailImage} alt="" /></Link></div>
            <div>
            <span>
                <h4>Request a Brochure</h4>
                <p>Get Your Tomcat Brochure Delivered</p>
                <button>Get Brochure Now</button>
                </span>
                <img src={WarehouseImage} alt="" /></div>
            <div>
            <span>TOMCAT YOU BE THE JUDGE</span>
                <img src={ManufacturingImage} alt="" /></div>
            <div>
            <span>TOMCAT CARES</span>
                <img src={SportsImage} alt="" /></div>
            </div>
        </div>
    )
}
