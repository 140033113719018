import React from 'react'
import './why.scss'
export default function Why() {
    return (
        <div className="why-wrapper">
            <h2>Our machines Last Longer</h2>
            <p>Built in a heavy duty fashion so that they will last 
                for a long time. At least 25 years of service for all 
                the wear and tear this machine would receive.
            </p>
            <p>Steel parts are all powder coated to protect against 
                erosion as well as to be the most ecologically sound "painting" process
            </p>
            <p>
            Our machines are programmed with a second delayed start to put the least 
            amount of strain on any of our motors
            </p>
        </div>
    )
}
