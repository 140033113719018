import React from "react";
import './index.scss'
import styled from "styled-components";
import Layout from "../components/Layout";
import HeroVideo from "../videos/tomcatherovideo.mp4";
import Carousel from "../components/Index/Carousel/Carousel";
import TomcatConnect from "../components/indexPage/TomcatConnect";
import IndexGallery from "../components/Index/IndexGallery.js/IndexGallery";
import PaddedLayout from "../components/PaddedLayout";
import Testimonials from "../components/Index/Testimonials/Testimonials";
import Why from "../components/Index/Why/Why";
const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 60vh;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 60vh;
  // background-color: rgba(0, 0, 0, 0.6);
`;



const IndexPage = () => {
  console.log("test")

  return (
    <Layout>
      <VideoContainer>
        <VideoOverlay />
        <video autoPlay muted loop>
          <source src={HeroVideo} type="video/mp4" />
        </video>
      </VideoContainer>
      <PaddedLayout> 
      <IndexGallery />
      </PaddedLayout>
      <Why />
      <Testimonials />
      <TomcatConnect />
      {/* <LatestNews /> */}
      {/* <SubscribeSection>
        <div className="subscribe-content">
          <h2 style={{ margin: "0" }}>Subscribe to Tomcat:</h2>
          <button>Stay Informed</button>
        </div>
      </SubscribeSection> */}
    </Layout>
  );
};

export default IndexPage;
